import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  CardActionArea,
  CardActions,
  Avatar,
  Divider,
Skeleton
} from "@mui/material";
import LeaderTable from "../Components/LeaderTable";
import { fetchLeaderBoard as getLeaderBoardData } from "../services/Analysis_api";
import MenuDrawer from "../Components/MenuDrawer";
import HeaderNew from "../Components/HeaderNew";
import MultipleSelect from "../Common-comp/SelectField";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../services/Context";
import TransitionsModal from "../Components/Modal";
import { encode, decode } from "base-64";
import { LeaderBoardCard } from "../Components/CustomizedAccordian";

function LeaderBoard() {
  const { menuBarOpen, setMenuBarOpen, Backdrop, isMobile } = useAuth();
  const { attemptId, mockId } = useParams();
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [mock, setMock] = useState([]);
  const [filter, setFilter] = useState(mockId); //Select particaular mock value
  const ref = useRef(null);


 
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isWindow = JSON.parse(window.localStorage.getItem("__wodniw"));
  const { _id, name, photoURL } = userData;

  function extractMockData(data) {
    const arr = [];
    if (data.length > 0) {
      data[0].mocks.forEach((item) => {
        if (item.mockId === mockId) {
          arr.unshift({
            name: item.title,
            value: item.mockId,
            attemptId: item.attemptList[0],
          });
        } 
        //else if (!arr.some((entry) => entry.value === mockId)) {
          else{
          arr.push({
            name: item.title,
            value: item.mockId,
            attemptId: item.attemptList[0],
          });
        }
      });
    }
    setLoading(false);
    return arr;
  }


const SkeletonCards = () => {
  return (
    <>
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          gap: 2,
          width: "100%",
          p: 2,
          boxShadow: 3,
          borderRadius: 5,
          border:"none"
          
        }}
      >
        <Skeleton animation="wave" variant="circular" width={50} height={50} />
        <Skeleton
          animation="wave"
          variant="rounded"
          // width="100%"
          height={60}
          style={{
            flexBasis: "auto",
            width: "100%",

            zIndex: 1,
          }}
        />
      </Card>
    </>
  );
};

  useEffect(() => {
    async function fetchLeaderBoard(attemptId) {
      try {
        setLoading(true);
        let obj =
          mock.length > 0
            ? mock.filter((itm) => itm.value == filter)[0]
            : { attemptId: attemptId };
        const res = await getLeaderBoardData(filter, obj.attemptId, _id);
        //console.log(res);
        if (res?.status == 200) {
          const data = res;
          const mockData = extractMockData(data.data);
          setMock(mockData);
          setData(data.data);
          setLoading(false);
        }
      } catch (err) {
        //console.log(err);
        setLoading(false);
        showToastMessage(err?.response?.data?.msg);
      }
    }
    const isWindow = JSON.parse(window.localStorage.getItem("__wodniw"));
    //console.log(isWindow);
    if (isWindow) {
      return;
    } else {
      fetchLeaderBoard(attemptId);
    }
  }, [filter]);

  console.log("mock", mock);
  console.log("data", Data);
  //console.log(filter, "fiilll");
  const showToastMessage = (msg) => {
    toast.error(
      msg == undefined
        ? "Some error occurred! Please reload the page."
        : msg.toUpperCase(),
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
    return (ref.current.style.display = "none");
  };

  return (
    <>
      <TransitionsModal isOpen={isWindow} />
      <ToastContainer />

      <div style={{ width: "100vw" }}>
        {!isMobile && <MenuDrawer open={menuBarOpen} />}

        <Box
          sx={{
            height: "auto",
            display: "flex",
            flexDirection: "column",
            p: 2,
            gap: "2em",
            position: "relative",
            ml: !isMobile && "65px",
            background: "#f0f1f2  ",
          }}
          ref={ref}
        >
          <Box component="div" sx={{ height: "10%" }}>
            <HeaderNew />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Backdrop
              sx={{
                zIndex: (theme) => theme.zIndex.drawer - 1,
                color: "#fff",
              }}
              open={menuBarOpen}
              onClick={() => setMenuBarOpen(false)}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
              }}
            >
              {" "}
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontSize: "30px",
                    fontWeight: 800,
                    color: "black",
                    fontFamily: "Inter",
                  }}
                >
                  Leader Board :
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#0057C8",
                  fontFamily: "Inter",
                  marginTop: "6px",
                }}
              >
                {name}
              </Typography>
            </Box>
            {mock.length > 0 && (
              <MultipleSelect options={mock} setType={setFilter} />
            )}
          </Box>

          {/* Table start */}
          <div>
            {/* <div className="mt-3">
              {Data && Data[0] && <LeaderBoardCard
                style={{
                  color: "#2e2e2e",
                  background: "#F5F9FF",
                  border: "black",
                }}
                Data={{
                  rank:`Your Rank(${Data[0] && Data[0]?.rank})`,
                  name: Data[0] && Data[0].result[0]?.name,
                  qaPercentile: Data[0] && Data[0].result[0]?.qaPercentile,
                  lrdiPercentile: Data[0] && Data[0].result[0]?.lrdiPercentile,
                  varcPercentile: Data[0] && Data[0].result[0]?.varcPercentile,
                  overallPercentile:
                    Data[0] && Data[0].result[0]?.overallPercentile,
                  overallScore: Data[0] && Data[0].result[0]?.overallScore,
                }}
              />}
            </div> */}
            {isMobile ? (
              loading ? (
                [...Array(10)].map((_, ind) => (
                  <div className="mt-3">
                    <SkeletonCards key={ind} />
                  </div>
                ))
              ) : (
                Data[0] && (
                  <>
                    {" "}
                    <LeaderBoardCard
                      style={{
                        color: "#2e2e2e",
                        background: "#F5F9FF",
                        border: "black",
                      }}
                      Data={{
                        rank: `Your Rank(${Data[0] && Data[0]?.rank})`,
                        name: Data[0] && Data[0].result[0]?.name,
                        qaPercentile:
                          Data[0] && Data[0].result[0]?.qaPercentile,
                        lrdiPercentile:
                          Data[0] && Data[0].result[0]?.lrdiPercentile,
                        varcPercentile:
                          Data[0] && Data[0].result[0]?.varcPercentile,
                        overallPercentile:
                          Data[0] && Data[0].result[0]?.overallPercentile,
                        overallScore:
                          Data[0] && Data[0].result[0]?.overallScore,
                      }}
                    />
                    {Data[0].leaderList.map((item, index) => (
                      <div className="mt-3">
                        <LeaderBoardCard
                          isLoading={loading}
                          style={{
                            color:
                              index == 0
                                ? "#e0e0e0"
                                : index == 1
                                ? "#e0e0e0"
                                : index == 2
                                ? "#e0e0e0"
                                : "#2e2e2e",
                            background:
                              index == 0
                                ? "#FF7A00"
                                : index == 1
                                ? "#4B87FC"
                                : index == 2
                                ? "#B53FFD"
                                : "white",
                            // background: "#F5F9FF",
                            border: "black",
                          }}
                          Data={{
                            rank: index + 1,
                            name: item.name,
                            qaPercentile: item.qaPercentile,
                            lrdiPercentile: item.lrdiPercentile,
                            varcPercentile: item.varcPercentile,
                            overallPercentile: item.overallPercentile,
                            overallScore: item.overallScore,
                          }}
                        />
                      </div>
                    ))}
                  </>
                )
              )
            ) : (
              <LeaderTable data={Data} isLoading={loading} />
            )}
          </div>
          {/* Table end */}
        </Box>
      </div>
    </>
  );
}

export default LeaderBoard;

const RankCard = ({ Data, style }) => {
  const {
    rank,
    name,
    qaPercentile,
    lrdiPercentile,
    varcPercentile,
    overallPercentile,
    overallScore,
  } = Data;
  const getRandomColor = () => {
    const colors = ["#F87171", "#34D399", "#60A5FA", "#FBBF24", "#A78BFA"];
    console.log(Math.floor(Math.random() * colors.length));
    return colors[Math.floor(Math.random() * colors.length)];
  };

  // Function to get Avatar content based on name
  const getAvatarContent = (name) => {
    const nameWords = name.split(" ");
    if (nameWords.length === 2) {
      return nameWords[0][0] + nameWords[1][0]; // First letter of first word + first letter of second word
    } else {
      return name.slice(0, 1); // First two letters of name
    }
  };

  return (
    <Card
      sx={{
        width: "fit -content",
        px: 2,
        borderRadius: 3,
        boxShadow: "2px 4px 4px rgba(63, 105, 255, 0.25)",
        background: style.background,
        color: style.color,
        mt: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex -start",
          alignItems: "center",
        }}
      >
        <Avatar
          alt={name}
          src="/static/images/avatar/1.jpg"
          sx={{ width: 40, height: 40, mb: 3, background: getRandomColor() }}
        >
          {getAvatarContent(name).toUpperCase()}
        </Avatar>
        <CardContent>
          <Box>
            <div
              className="d-flex border-bottom  gap-3 align-items-center align-content-center "
              style={{ borderBottom: style.border }}
            >
              <Typography gutterBottom variant="paragraph" color={style.color}>
                {name}
              </Typography>

              <Typography gutterBottom variant="paragraph" color={style.color}>
                <span>
                  <img src="/medal.png" width={30} className="img-fluid " />
                </span>{" "}
                Rank : {rank}
              </Typography>
            </div>

            <div className="pt-2 d-flex flex-wrap justify-content-between gap-3">
              <Typography gutterBottom variant="paragraph" color={style.color}>
                QUANT <br />
                {` ${qaPercentile}%ile`}
              </Typography>

              <Typography gutterBottom variant="paragraph" color={style.color}>
                LRDI
                <br />
                {` ${lrdiPercentile}%ile`}
              </Typography>

              <Typography gutterBottom variant="paragraph" color={style.color}>
                VARC
                <br />
                {` ${varcPercentile}%ile`}
              </Typography>

              <Typography gutterBottom variant="paragraph" color={style.color}>
                Overall
                <br />
                {` ${overallPercentile}%ile`}
              </Typography>

              <Typography gutterBottom variant="paragraph" color={style.color}>
                Overall Score
                <br />
                {overallScore}
              </Typography>
            </div>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};
